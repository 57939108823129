import React, { useGlobal, useRef } from "reactn"
import { graphql } from "gatsby"
import Img, { ImgProps } from "../components/Img"
import Slider from "../components/Slider"
import autoscroll from "../utils/autoscroll"
import marked from "marked"
import PageHeader from "../components/PageHeader"
import VimeoPlayer from "../components/VimeoPlayer"
import LazyLoad from "react-lazyload"
import LazyLoadPlaceholder from "../components/LazyLoadPlaceholder"
import styled from "styled-components"

const SplashContainer = styled.section`
  &::before {
    content: "";
    height: 60px;
    background-image: url("/img/bg-edge-mask.svg");
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 1;
  }
  &::after {
    content: "";
    background-color: #080e1d9e;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    backdrop-filter: blur(5px);
  }
`

interface PartnerskapPageTemplateProps {
  start: {
    title: string,
    button1: string,
    button2: string,
    text: string,
    image: ImgProps
  },

  opportunities: {
    title: string,
    question: string,
    choices: {
      name: string,
      icon: ImgProps,
      example: {
        title: string,
        text: string,
        images: ImgProps[]
      }
    }[]
  },

  about: {
    title: string,
    text: string,
    video: {
      title: string,
      vimeoId: string
    }
  },

  services: {
    title: string,
    list: {
      title: string,
      text: string,
      icon: ImgProps
    }[]
  },

  projects: {
    title: string,
    list: {
      title: string,
      url: string,
      image: ImgProps
    }[]
  },

  partners: {
    title: string,
    logos: ImgProps
  },

  cta: {
    title: string,
    text: string,
    button: string,
    background: ImgProps
  }
}

const PartnerskapPageTemplate: React.FC<PartnerskapPageTemplateProps> = ({
  start,
  opportunities,
  about,
  services,
  projects,
  partners,
  cta
}) => {
  const { 1: setShowContactDialog } = useGlobal<any>("showContactDialog")
  const choiceSlider = useRef()

  return (
    <main id="index-page">
      <SplashContainer
        id="start-soket"
        className="px-6 pt-12 pb-16 relative bg-cover bg-bottom text-white flex justify-center flex-col h-screen-3/4 xl:h-auto"
        style={{
          backgroundImage: `url(${start.image.src})`
        }}
      >
        <div
          className="relative w-full max-w-4xl mx-auto sm:py-0 md:16 xl:py-24"
          style={{ zIndex: 1 }}
        >
          <div className="max-w-xl">
            <h1
              className="text-5xl font-semibold tracking-tight md:text-4xl"
              dangerouslySetInnerHTML={{
                __html: marked(start.title)
              }}
            />
          </div>

          <div className="my-12">
            <button
              className="mr-2 xs:mr-1 xs:px-3 bg-accent border-2 border-solid border-accent text-white px-6 py-2 rounded-sm font-secondary text-base hover:bg-alt hover:border-alt"
              onClick={() => { setShowContactDialog(true) }}
              dangerouslySetInnerHTML={{
                __html: marked(start.button1)
              }}
            />
            <button
              className="ml-2 xs:ml-1 xs:px-3 px-6 py-2 border-2 border-solid rounded-sm font-secondary text-base hover:bg-gray-800 hover:border-gray-800 hover:text-white"
              onClick={() => {
                autoscroll({
                  destination: document.querySelector("#mulighetene")
                })
              }}
              dangerouslySetInnerHTML={{
                __html: marked(start.button2)
              }}
            />
          </div>

          <div className="max-w-sm">
            <div
              dangerouslySetInnerHTML={{
                __html: marked(start.text)
              }}
            />
          </div>
        </div>
      </SplashContainer>

      <section
        id="mulighetene"
        className="px-6 pt-24 pb-32 sm:py-16"
      >
        <div className="max-w-4xl mx-auto">
          <div className="max-w-xl mx-auto text-center">
            <h2
              className="text-4xl font-semibold md:text-3xl sm:text-2xl"
              dangerouslySetInnerHTML={{
                __html: marked(opportunities.title)
              }}
            />
          </div>

          <hr className="mx-auto my-12 h-px pt-px bg-black w-16" />

          <h3
            className="text-xl font-semibold text-center md:text-lg"
            dangerouslySetInnerHTML={{
              __html: marked(opportunities.question)
            }}
          />

          <div className="mt-6 border-b-2 border-solid border-gray-400 pb-4 text-center">
            {opportunities.choices.map((choice, index) => (
              <button
                className="inline-block px-10 hover:bg-gray-100 py-6 lg:px-8 md:px-6 md:py-4 sm:px-5"
                key={index}
                onClick={() => {
                  choiceSlider.current.slideTo(index)
                }}
              >
                <Img
                  className="w-12 mx-auto"
                  src={choice.icon.src}
                  alt={choice.icon.alt}
                />
                <p className="font-semibold text-sm mt-2">
                  {choice.name}
                </p>
              </button>
            ))}
          </div>

          <Slider
            className="mt-6"
            ref={choiceSlider}
            showNav={false}
            options={{
              noSwiping: true
            }}
          >
            {opportunities.choices.map((choice, index) => (
              <div
                className="flex items-center lg:flex-col"
                key={index}
              >
                <div className="w-1/2 lg:w-full">
                  <Slider options={{ lazy: true }}>
                    {choice.example.images.map((image, i) => (
                      <div key={i}>
                        <Img
                          className="mx-auto"
                          src={image.src}
                          alt={image.alt}
                        />
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className="w-1/2 pl-6 lg:w-full lg:pl-0 lg:mt-6">
                  <h4
                    className="font-semibold mb-6 text-xl md:text-lg"
                    dangerouslySetInnerHTML={{
                      __html: marked(choice.example.title)
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: marked(choice.example.text)
                    }}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      <section
        id="om-bonum"
        className="px-6 py-32 relative sm:py-16"
      >
        <div className="shadow-bg-1" />

        <div className="max-w-xl mx-auto text-center">
          <h2
            className="text-4xl font-semibold md:text-3xl sm:text-2xl"
            dangerouslySetInnerHTML={{
              __html: marked(about.title)
            }}
          />

          <hr className="mx-auto my-12 h-px pt-px bg-black w-16" />

          <div
            dangerouslySetInnerHTML={{
              __html: marked(about.text)
            }}
          />
        </div>

        <div className="mt-12 mx-auto max-w-6xl relative">
          <LazyLoad
            height={400}
            offset={200}
            placeholder={<LazyLoadPlaceholder height={400} />}
            once
          >
            <VimeoPlayer
              id="om-bonum"
              className="border-4 border-solid border-white bg-dark shadow-2xl"
              title={about.video.title}
              vimeoId={about.video.vimeoId}
              playsinline={true}
            />
          </LazyLoad>
        </div>
      </section>

      <section
        id="tjenester"
        className="px-6 py-32 relative sm:py-16"
      >
        <div className="mx-auto max-w-4xl text-center">
          <h2
            className="text-4xl font-semibold md:text-3xl sm:text-2xl"
            dangerouslySetInnerHTML={{
              __html: marked(services.title)
            }}
          />
        </div>

        <hr className="mx-auto my-12 h-px pt-px bg-black w-16" />

        <div className="mx-auto max-w-4xl flex flex-wrap px-3">
          {services.list.map((service, index) => (
            <div
              className="px-3 w-1/3 mb-12 sm:w-1/2 xs:w-full"
              key={index}
            >
              <Img
                className="w-10"
                src={service.icon.src}
                alt={service.icon.alt}
              />
              <h3 className="mt-6 text-base font-semibold my-12">
                {service.title}
              </h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: marked(service.text)
                }}
              />
            </div>
          ))}
        </div>
      </section>

      <section
        id="prosjekter"
        className="px-6 py-32 relative sm:py-16"
      >
        <div className="shadow-bg-2" />

        <div className="mx-auto max-w-6xl">
          <h2
            className="text-4xl font-semibold mb-12 md:text-3xl sm:text-2xl"
            dangerouslySetInnerHTML={{
              __html: marked(projects.title)
            }}
          />

          <Slider
            options={{
              slidesPerView: 2,
              spaceBetween: 20,
              breakpoints: {
                1279: {
                  slidesPerView: 4
                },
                1023: {
                  slidesPerView: 3
                },
                639: {
                  slidesPerView: 2
                }
              }
            }}
          >
            {projects.list.map((project, index) => (
              <div key={index}>
                <Img
                  className="w-full object-cover object-center rounded-lg h-64 sm:h-56 xs:h-40"
                  src={project.image.src}
                  alt={project.image.alt}
                />

                <h3 className="text-lg font-semibold mt-4 mb-0">
                  {project.title}
                </h3>

                <a
                  href={project.url}
                  target="_blank"
                  rel="noopener"
                  className="text-sm"
                >
                  Se mer &rarr;
                </a>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      <section
        id="partnere"
        className="px-6 py-32 relative sm:py-16"
      >
        <div className="shadow-bg-1" />

        <div className="mx-auto max-w-xl text-center">
          <h2
            className="text-4xl font-semibold md:text-3xl sm:text-2xl"
            dangerouslySetInnerHTML={{
              __html: marked(partners.title)
            }}
          />
        </div>

        <hr className="mx-auto my-12 h-px pt-px bg-black w-16" />

        <div className="mx-auto max-w-2xl">
          <Img
            src={partners.logos.src}
            alt={partners.logos.alt}
          />
        </div>
      </section>

      <section
        id="kom-i-gang"
        className=""
      >
        <div
          className="mx-auto bg-top bg-cover px-6 py-32 sm:py-16"
          style={{
            backgroundImage: `url(${cta.background.src})`,
            maxWidth: 1600
          }}
        >
          <div className="mx-auto max-w-xl text-center">
            <h2
              className="text-4xl font-semibold md:text-3xl sm:text-2xl"
              dangerouslySetInnerHTML={{
                __html: marked(cta.title)
              }}
            />

            <div
              className="my-12"
              dangerouslySetInnerHTML={{
                __html: marked(cta.text)
              }}
            />

            <button
              className="bg-accent border-2 border-solid border-accent text-white px-8 py-2 rounded-sm font-secondary text-base shadow-xl hover:bg-alt hover:border-alt"
              onClick={() => { setShowContactDialog(true) }}
            >
              {cta.button}
            </button>
          </div>
        </div>
      </section>
    </main>
  )
}

interface PartnerskapPageProps {
  data: {
    markdownRemark: {
      frontmatter: PartnerskapPageTemplateProps
    }
  }
}

const PartnerskapPage: React.FC<PartnerskapPageProps> = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <>
      <PageHeader />

      <PartnerskapPageTemplate
        start={frontmatter.start}
        opportunities={frontmatter.opportunities}
        about={frontmatter.about}
        services={frontmatter.services}
        projects={frontmatter.projects}
        partners={frontmatter.partners}
        cta={frontmatter.cta}
      />
    </>
  )
}

export default PartnerskapPage

export const PartnerskapPageQuery = graphql`
  query PartnerskapPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        start {
          title
          button1
          button2
          text
          image {
            src
            alt
          }
        }

        opportunities {
          title
          question
          choices {
            name
            icon {
              src
              alt
            }
            example {
              title
              text
              images {
                src
                alt
              }
            }
          }
        }

        about {
          title
          text
          video {
            title
            vimeoId
          }
        }

        services {
          title
          list {
            title
            text
            icon {
              src
              alt
            }
          }
        }

        projects {
          title
          list {
            title
            url
            image {
              src
              alt
            }
          }
        }

        partners {
          title
          logos {
            src
            alt
          }
        }

        cta {
          title
          text
          button
          background {
            src
            alt
          }
        }
      }
    }
  }
`
